<template>
  <div  @scroll="updateActiveTab">
    <HeaderView :page_index="-1" :need_fixed="true"></HeaderView>
    <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left; margin: 30px;" />
    <div v-else class="home" >
      <el-container >
        <el-container>
          <el-aside width="200px" id="sidebar" style="padding-top: 60px;">
            <div :class="['tab', activeIndex==0 ? 'active' : '']" @click="scrollToSection(0)">关于我们</div>
            <div :class="['tab', activeIndex==1 ? 'active' : '']" @click="scrollToSection(1)">使用说明</div>
            <div :class="['tab', activeIndex==2 ? 'active' : '']" @click="scrollToSection(2)">联系我们</div>
          </el-aside>
          <el-container style="margin: 0px 20px 20px 30px;">
            <el-skeleton v-if="loading3" :rows="5" animated  style="text-align: left;" />
            <el-main  v-else style="text-align: left;">
              <div id="content">
                <div id="sections">
                  <div id="about" class="section" >
                    <div  style="margin:18px 0px 10px 0px;"> <el-tag class="ml-2" size="large">关于我们</el-tag></div>
                    <ul class="abouts">
                      <div  v-for="(list, i) in about_list" :key="i">
                        <li v-for="(info, index) in list" :key="index" >
                          <div v-if="info.type === 'title'" class="title">
                            {{ info.content }}
                          </div>
                          <div v-else-if="info.type === 'list'" class="descs">
                            <div v-for="(desc, index) in info.content" :key="index" >{{ desc }}</div>
                          </div>
                          <div v-else>
                            {{ info.content }}
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div id="explain" class="section" >
                    <div  style="margin:18px 0px 10px 0px;"> <el-tag class="ml-2" size="large">使用说明</el-tag></div>
                    <ul class="abouts">
                      <div  v-for="(list, i) in explain_list" :key="i">
                        <li v-for="(info, index) in list" :key="index" >
                          <div v-if="info.type === 'title'" class="title">
                            {{ info.content }}
                          </div>
                          <div v-else-if="info.type === 'list'" class="descs">
                            <div v-for="(desc, index) in info.content" :key="index" >{{ desc }}</div>
                          </div>
                          <div v-else>
                            {{ info.content }}
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div id="contact" class="section">
                    <div  style="margin:18px 0px 10px 0px;"> <el-tag class="ml-2" size="large">联系我们</el-tag></div>

                    <ul>
                      <div  v-for="(list, i) in contact_list" :key="i">
                        <li v-for="(info, index) in list" :key="index" >
                          <div v-if="info.type === 'title'">
                            {{ info.content }}
                          </div>
                          <div v-else-if="info.type === 'list'" class="descs">
                            <div v-for="(desc, index) in info.content" :key="index" >{{ desc }}</div>
                          </div>
                          <div v-else>
                            {{ info.content }}
                          </div>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
                <div id="others" style="height: 360px;">
                </div>
              </div>
            </el-main>
          </el-container>
        </el-container>
      </el-container>
      <FooterView show_what="line2" page_name="About" />
    </div>
  </div>
  </template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import HeaderView from '@/components/other/HeaderView'
import FooterView from '@/components/other/FooterView'

export default {
  name: 'About',
  mounted () {
    this.get_index()
    window.addEventListener('scroll', this.updateActiveTab) // 监听页面滚动
  },
  components: {
    FooterView, HeaderView
  },
  data () {
    return {
      loading: true,
      niming: store.state.niming,
      about_list: [],
      explain_list: [],
      contact_list: [],
      activeIndex: -1
    }
  },
  methods: {

    get_index (e) {
      var _this = this
      axios.post(Utils.check_is_niming() ? '/about_index_unlogin/' : '/about_index/', {})
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.about_list = res.data.data.about_list
          this.contact_list = res.data.data.contact_list
          this.explain_list = res.data.data.explain_list
          this.loading = false

          setTimeout(function () {
            const hash = window.location.hash
            console.log(222, hash)
            if (!hash) {
              // document.body.scrollTop = 0
              _this.scrollTop()
            } else {
              _this.scrollToAnchor(hash)
              // const element = document.querySelector(hash)
              // if (element) {
              //   element.scrollIntoView({ behavior: 'smooth' })
              // }
            }
            _this.updateActiveTab()
          }, 100)
          // const hash = window.location.hash
          // console.log(222, hash)
          // if (!hash) {
          //   this.scrollTop()
          // } else {
          //   this.scrollToAnchor(hash)
          // }
        })
    },
    scrollToAnchor (hash) {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    scrollTop () {
      document.body.scrollTop = 0
    },
    scrollToSection (index) {
      var sections = document.getElementsByClassName('section')
      var section = sections[index]
      section.scrollIntoView({ behavior: 'smooth' })
      // this.activeIndex = index
    },
    updateActiveTab () {
      console.log(33333)

      var sections = document.getElementsByClassName('section')
      var tabs = document.getElementsByClassName('tab')

      var activeIndex = -1
      var ScreenMiddleY = window.innerHeight / 2

      var rect0 = document.getElementById('others').getBoundingClientRect()

      console.log(rect0.top)

      if (rect0.top < 0) {
        for (let i = 0; i < tabs.length; i++) {
          tabs[i].classList.remove('active')
        }
      } else {
        for (let i = 0; i < sections.length; i++) {
          var section = sections[i]
          var rect = section.getBoundingClientRect()
          var sectionTop = rect.top

          if (sectionTop < ScreenMiddleY && sectionTop >= 0) {
            activeIndex = i
            console.log(activeIndex, this.activeIndex)
            this.activeIndex = i
            break
          }
        }

        if (activeIndex === -1) {
          for (let i = 0; i < sections.length; i++) {
            const section = sections[i]
            const rect = section.getBoundingClientRect()
            const sectionTop = rect.top
            if (sectionTop < ScreenMiddleY) {
              activeIndex = i
              this.activeIndex = i
            }
            console.log('xx', activeIndex, this.activeIndex)
          }
        }
        // for (let i = 0; i < tabs.length; i++) {
        //   const tab = tabs[i]
        //   tab.classList.remove('active')
        // }
        // if (activeIndex !== -1) {
        //   tabs[activeIndex].classList.add('active')
        // }
      }
    }
  }
}
</script>
<style scoped>

#sidebar {
  position: fixed;
  width: 200px;
  /* background-color: #1ec990; */
}

.tab {
  margin: 16px 16px;
  padding: 6px 5px;
  background: lightblue;
  cursor: pointer;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
}
.active {
  color: #50b7c1;
  /* color: #ffd04b; */
}

.section {
  margin: 0px 30px 20px 170px;
  padding-top: 60px;
  /* background-color: #f2f2f2; */
  /* width: 900px; */
}

.section li{
  line-height: 32px;
}
.descs{
  margin-bottom: 15px;
}
.title{
  color: #50b7c1;
  margin-top: 20px;
}
</style>
